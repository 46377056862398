import {  
    CONTENT_TYPE_LIST_FAIL, 
    CONTENT_TYPE_LIST_SUCCESS,
    CONTENT_TYPE_CREATE_REQUEST,
    CONTENT_TYPE_CREATE_SUCCESS,
    CONTENT_TYPE_CREATE_FAIL,
    CONTENT_TYPE_CREATE_RESET,
    CONTENT_TYPE_UPDATE_REQUEST,
    CONTENT_TYPE_UPDATE_SUCCESS,
    CONTENT_TYPE_UPDATE_FAIL,
    CONTENT_TYPE_UPDATE_RESET,
    CONTENT_TYPE_DELETE_REQUEST,
    CONTENT_TYPE_DELETE_SUCCESS,
    CONTENT_TYPE_DELETE_FAIL 
} from "../Constants/ContentTypeConstants";

export const contentTypeListReducer = (state = { contentTypes: [] }, action) => {
    switch (action.type) {
        case CONTENT_TYPE_LIST_SUCCESS:
            return { loading: false, contentTypes: action.payload };
        case CONTENT_TYPE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const contentTypeCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_TYPE_CREATE_REQUEST:
            return { loading: true };
        case CONTENT_TYPE_CREATE_SUCCESS:
            return { loading: false, success: true, contentType: action.payload };
        case CONTENT_TYPE_CREATE_FAIL:
            return { loading: false, error: action.payload };
        case CONTENT_TYPE_CREATE_RESET:
            return {};
        default:
            return state;
    }
};

export const contentTypeUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_TYPE_UPDATE_REQUEST:
            return { loading: true };
        case CONTENT_TYPE_UPDATE_SUCCESS:
            return { loading: false, success: true, contentType: action.payload };
        case CONTENT_TYPE_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case CONTENT_TYPE_UPDATE_RESET:
            return {};
        default:
            return state;
    }
};

export const contentTypeDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_TYPE_DELETE_REQUEST:
            return { loading: true };
        case CONTENT_TYPE_DELETE_SUCCESS:
            return { loading: false, success: true };
        case CONTENT_TYPE_DELETE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};