import { 
    CONTENT_TYPE_LIST_FAIL, 
    CONTENT_TYPE_LIST_SUCCESS,
    CONTENT_TYPE_CREATE_REQUEST,
    CONTENT_TYPE_CREATE_SUCCESS,
    CONTENT_TYPE_CREATE_FAIL,
    CONTENT_TYPE_UPDATE_REQUEST,
    CONTENT_TYPE_UPDATE_SUCCESS,
    CONTENT_TYPE_UPDATE_FAIL,
    CONTENT_TYPE_DELETE_REQUEST,
    CONTENT_TYPE_DELETE_SUCCESS,
    CONTENT_TYPE_DELETE_FAIL
} from "../Constants/ContentTypeConstants";
import axios from "axios";
import { BASE_URL } from "../../api/baseConfig";

// Get ContentType List
export const contentTypeList = (langKey)=> async (dispatch, getState) => {
    try {
        const { 
            userLogin: { userInfo },
        } = getState();
        
        const { data } = await axios.get(
            `${BASE_URL}/api/contenttype/getall/${langKey}`,
            {
                headers: {
                    "x-access-token": userInfo.token,
                },
            }
        );
        dispatch({type: CONTENT_TYPE_LIST_SUCCESS, payload: data});
    } catch (error) {
        dispatch({
            type: CONTENT_TYPE_LIST_FAIL, 
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        });
    }
}

// Create ContentType
export const createContentType = (contentType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTENT_TYPE_CREATE_REQUEST });
        
        const { 
            userLogin: { userInfo },
        } = getState();
        
        const { data } = await axios.post(
            `${BASE_URL}/api/contenttype/add`,
            contentType,
            {
                headers: {
                    "x-access-token": userInfo.token,
                },
            }
        );
        
        dispatch({ type: CONTENT_TYPE_CREATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTENT_TYPE_CREATE_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        });
    }
};

// Update ContentType
export const updateContentType = (id, contentType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTENT_TYPE_UPDATE_REQUEST });
        
        const { 
            userLogin: { userInfo },
        } = getState();
        
        const { data } = await axios.put(
            `${BASE_URL}/api/contenttype/update/${id}`,
            contentType,
            {
                headers: {
                    "x-access-token": userInfo.token,
                },
            }
        );
        
        dispatch({ type: CONTENT_TYPE_UPDATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: CONTENT_TYPE_UPDATE_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        });
    }
};

// Delete ContentType
export const deleteContentType = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: CONTENT_TYPE_DELETE_REQUEST });
        
        const { 
            userLogin: { userInfo },
        } = getState();
        
        await axios.delete(
            `${BASE_URL}/api/contenttype/delete/${id}`,
            {
                headers: {
                    "x-access-token": userInfo.token,
                },
            }
        );
        
        dispatch({ type: CONTENT_TYPE_DELETE_SUCCESS });
    } catch (error) {
        dispatch({
            type: CONTENT_TYPE_DELETE_FAIL,
            payload: error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
        });
    }
};
