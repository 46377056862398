import {
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  FIND_USER_FAIL,
  FIND_USER_REQUEST,
  FIND_USER_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../Constants/UserContants";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../api/baseConfig";

// LOGIN
export const login = (email, password) => async (dispatch, getState) => {
  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClose: 2000,
  };

  try {
    dispatch({ type: USER_LOGIN_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}/api/user/login`,
      { email: email, password: password },
      config
    );
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    // if (!data.user.claims.includes("Admin")) {
    //     toast.error("You are not Admin", ToastObjects);
    //   dispatch({
    //     type: USER_LOGIN_FAIL,
    //   });
    // } else {
    //   dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    //   localStorage.setItem("userInfo", JSON.stringify(data));
    // }
    if (data.user.claims.includes("Admin") || data.user.claims.includes('partner')) {

      dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));

    } else {
      toast.error("You are not Admin", ToastObjects);
      dispatch({
        type: USER_LOGIN_FAIL,
      });
    }

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: message,
    });
  }
};

// LOGOUT
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_LIST_RESET });
};

// ALL USER
export const listUser = ({ page, q, paymentCode }) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "x-access-token": `${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${BASE_URL}/api/user/getalluser?page=${page}&q=${q}&paymentCode=${paymentCode}`, config);

    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

// ALL ODERO SUBSCRIPTION
export const listOderoSubscription = ({ page, q }) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "x-access-token": `${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${BASE_URL}/api/payment/getplans?page=${page}&q=${q}`, config);

    dispatch({ type: USER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    });
  }
};

// FIND WITH EMAIL
export const findUser = ({ user_id }) => async (dispatch, getState) => {
  try {
    dispatch({ type: FIND_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "x-access-token": `${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`${BASE_URL}/api/user/check`,{user_id:user_id}, config);
    dispatch({ type: FIND_USER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FIND_USER_FAIL,
      payload: message,
    });
  }
};

export const cancelSubscription = ({ email }) => async (dispatch, getState) => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "x-access-token": `${userInfo.token}`,
      },
    };
    const { status,message } = await axios.post(`${BASE_URL}/api/payment/plan/cancel/email`,{email:email}, config);
    if(status===200){
      dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS});
    }else{
      dispatch({ type: CANCEL_SUBSCRIPTION_FAIL,message:message});
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CANCEL_SUBSCRIPTION_FAIL,message
    });
  }
};