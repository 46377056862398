import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Series from "./Series";
import { useDispatch, useSelector } from "react-redux";
import { listSerie } from "../../Redux/Actions/SerieAction";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { listPlatforms } from "../../Redux/Actions/PlatformActions";
import { listCatalogs } from "../../Redux/Actions/CatalogActions";
import { contentTypeList } from "../../Redux/Actions/ContentTypeActions";
import ReactPaginate from "react-paginate";

const MainSeries = () => {

  const dispatch = useDispatch();

  const [platformId, setPlatformId] = useState("");
  const [catalogId, setCatalogId] = useState("");
  const [q, setQ] = useState("");
  let [page, setPage] = useState(1);

  const [contentTypeId, setContentTypeId] = useState(null);
  const platformInfo = useSelector((state) => state.platformList);
  const contentTypeInfo = useSelector((state) => state.contentTypeList);
  const catalogInfo = useSelector((state) => state.catalogList);

  const { platforms } = platformInfo;
  const { catalogs } = catalogInfo;
  const { contentTypes } = contentTypeInfo;

  const serieList = useSelector((state) => state.serieList);
  const { loading, error, series } = serieList;
  const serieDelete = useSelector((state) => state.serieDelete);
  const { error: errorDelete, success: successDelete } = serieDelete;
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  useEffect(() => {
    dispatch(
      listSerie({
        lang: "AZ",
        platformId,
        catalogId,
        q: q.toLowerCase(),
        type: contentTypeId ? contentTypeId : "serial",
        page,
      })
    );
    dispatch(listPlatforms());
    dispatch(listCatalogs({ lang: "AZ", page: 1 }));
    dispatch(contentTypeList("AZ"))
  }, [dispatch, successDelete, platformId, catalogId, contentTypeId, q, page]);
  return (
    <section className="content-main">
      <div className="content-header">
        <h2 className="content-title">Series</h2>
        <div>
          <Link to="/addSeries" className="btn btn-primary">
            Create new
          </Link>
        </div>
      </div>

      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white ">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto ">
              <input
                type="search"
                placeholder="Search..."
                className="form-control p-2"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select
                // defaultValue={}
                className="form-select"
                onChange={(e) => setContentTypeId(e.target.value)}
              >
                {/* <option value="">Content Type ...</option> */}
                {contentTypes &&
                  contentTypes.filter(c => c.type !== "film").map((p) => (
                    <option value={p.slug} key={p.id}>
                      {p.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select
                defaultValue={""}
                className="form-select"
                onChange={(e) => setPlatformId(e.target.value)}
              >
                <option value="">Platform ...</option>
                {platforms &&
                  platforms.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-lg-2 col-6 col-md-3">
              <select
                defaultValue={""}
                className="form-select"
                onChange={(e) => setCatalogId(e.target.value)}
              >
                <option value="">Catalog ...</option>

                {catalogs &&
                  catalogs.data?.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </header>
        <div className="card-body">

          {errorDelete && (
            <Message variant="alert-danger">{errorDelete}</Message>
          )}
          {loading ? (
            <Loading />
          ) : error ? (
            <Message variant="alert-danger">{error}</Message>
          ) : (
            <div className="row">
              {/* Products */}
              <button className="btn btn-dark btn-lg">Ümumi: {series.total}</button>
              {series.data?.map((product) => (
                <Series serie={product} key={product.id} />
              ))}
            </div>
          )}
          <div className="d-flex justify-content-end">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next>"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={series?.total>0 ? Math.ceil(series.total / 20) : 1}
              previousLabel="< previous"
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
              renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSeries;
