import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import UserDetailComponent from "../components/users/UserDetailComponent";

const UsersScreen = ({match}) => {
  const user_id = match.params.user_id;

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <UserDetailComponent user_id={user_id} />
      </main>
    </>
  );
};

export default UsersScreen;
