import { BASE_URL, CONTENT_KEY } from './baseConfig'
import axios from 'axios';

function getHeaderWithToken() {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return {'x-access-token': token}
}

export function getInteractiveFilms() {
    return axios.get(BASE_URL + '/api/filter/contentsoptional/AZ?type=interactive')
}

export function getInteractiveScenarios(filmId) {
    const url = `${BASE_URL}/api/interactive/scenario/getall/${filmId}`
    return axios.get(url)
}

export async function getInteractiveScenario(scenarioId) {
    const url = `${BASE_URL}/api/interactive/scenario/getbyid/${scenarioId}`
    return axios.get(url)
}

export function deleteInteractiveFilm(id) {
    const tokenHeader = getHeaderWithToken()
    return axios.delete(
        `${BASE_URL}/api/film/delete/${id}`,
        {headers: tokenHeader}
    )
}

export function updateInteractiveScenario(scenarioId, data) {
    const url = `${BASE_URL}/api/interactive/scenario/update/${scenarioId}`
    return axios.put(url, data)
}

export function deleteInteractiveScenario(scnearioId) {
    const url = `${BASE_URL}/api/interactive/scenario/delete/${scnearioId}`
    return axios.delete(url)
}

export function addScenario(filmId, scenario) {
    const url = `${BASE_URL}/api/interactive/scenario/${filmId}/add`
    return axios.post(url, scenario)
}

export function getCategoryList({lang,page,q}) {
    return axios.get(BASE_URL + `/api/category/getall/${lang}?page=${page}&q=${q}`)
}

export function getCategoryById(id) {
    return axios.get(BASE_URL + `/api/category/getbyid/${id}`)
}

export function createCategory(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/category/create', data, {headers: {'x-access-token': token}})
}

export function updateCategory(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/category/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getActorList({page}) {
    return axios.get(BASE_URL + `/api/actors/getall?page=${page}`)
}

export function getActorById(id) {
    return axios.get(BASE_URL + `/api/actors/getbyid/${id}`)
}

export function createActor(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/actors/create', data, {headers: {'x-access-token': token}})
}

export function updateActor(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/actors/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getDirectorList({page}) {
    return axios.get(BASE_URL + `/api/directors/getall?page=${page}`)
}

export function getDirectorById(id) {
    return axios.get(BASE_URL + `/api/directors/getbyid/${id}`)
}

export function createDirector(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/directors/create', data, {headers: {'x-access-token': token}})
}

export function updateDirector(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/directors/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getCatalogList({lang,page,q}) {
    return axios.get(BASE_URL + `/api/catalogs/getall/${lang}?page=${page}&q=${q}`)
}

export function getCatalogById(id) {
    return axios.get(BASE_URL + `/api/catalogs/getbyid/${id}`)
}

export function createCatalog(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/catalogs/create', data, {headers: {'x-access-token': token}})
}

export function updateCatalog(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/catalogs/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getLanguageList() {
    return axios.get(BASE_URL + '/api/language/getall')
}

export function getPlatformList() {
    return axios.get(BASE_URL + '/api/platform/getall')
}

export function getPlatformById(id) {
    return axios.get(BASE_URL + `/api/platform/getbyid/${id}`)
}

export function createPlatform(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/platform/create', data, {headers: {'x-access-token': token}})
}

export function updatePlatform(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/platform/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getContentTypeList({lang}) {
    return axios.get(BASE_URL + `/api/contenttype/getall/${lang}`)
}

export function getContentTypeById(id) {
    return axios.get(BASE_URL + `/api/contenttype/getbyid/${id}`)
}

export function createContentType(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/contenttype/create', data, {headers: {'x-access-token': token}})
}

export function updateContentType(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/contenttype/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getPartnerList({startDate, endDate}) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.get(BASE_URL + `/api/partner/getall?start_date=${startDate}&end_date=${endDate}`, {headers: {'x-access-token': token}})
}

export function getPartnerById(id) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.get(BASE_URL + `/api/partner/getbyid/${id}`, {headers: {'x-access-token': token}})
}

export function createPartner(data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.post(BASE_URL + '/api/partner/create', data, {headers: {'x-access-token': token}})
}

export function updatePartner(id, data) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.put(BASE_URL + `/api/partner/update/${id}`, data, {headers: {'x-access-token': token}})
}

export function getFilmList({lang,page,q,type,platformId,catalogId}) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.get(BASE_URL + `/api/filter/contentsoptional/${lang}?page=${page}&q=${q}&type=${type}&platform=${platformId}&catalog=${catalogId}`, {headers: {'x-access-token': token}})
}

export function getFilmById(id) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.get(BASE_URL + `/api/film/AZ/getbyidlang/${id}`, {headers: {'x-content-key': CONTENT_KEY, 'x-access-token': token}})
}

export function createFilm({film, files}) {
    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    film.content_date = date
    const formData = new FormData()
    formData.append('image', files.mainImg)
    formData.append('backgroundImg', files.mainBack)
    formData.append('products', JSON.stringify(film))
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token
        }
    }
    return axios.post(BASE_URL + '/api/film/add', formData, config)
}

export function updateFilm({id, film, files}) {
    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    film.content_date = date
    const formData = new FormData()
    formData.append('image', files.mainImg)
    formData.append('backgroundImg', files.mainBack)
    formData.append('products', JSON.stringify(film))
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token
        }
    }
    return axios.put(BASE_URL + `/api/film/update/${id}`, formData, config)
}


export function getSerieList({lang,page,q,type,platformId,catalogId}) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.get(BASE_URL + `/api/filter/contentsoptional/${lang}?page=${page}&q=${q}&type=${type}&platform=${platformId}&catalog=${catalogId}`, {headers: {'x-access-token': token}})
}

export function getSerieById(id) {
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    return axios.get(BASE_URL + `/api/tvshow/getbyidlang/AZ/${id}`, {headers: {'x-access-token': token}})
}

export function createSerie({serie, files}) {
    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    serie.content_date = date
    const formData = new FormData()
    formData.append('image', files.mainImg)
    formData.append('backgroundImg', files.mainBack)
    formData.append('products', JSON.stringify(serie))
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token
        }
    }
    return axios.post(BASE_URL + '/api/tvshow/add', formData, config)
}

export function updateSerie({id, serie, files}) {
    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    serie.content_date = date
    const formData = new FormData()
    formData.append('image', files.mainImg)
    formData.append('backgroundImg', files.mainBack)
    formData.append('products', JSON.stringify(serie))
    const token = JSON.parse(localStorage.getItem('userInfo')).token
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': token
        }
    }
    return axios.put(BASE_URL + `/api/tvshow/update/${id}`, formData, config)
}







