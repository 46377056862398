import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loading from '../LoadingError/Loading'
import {cancelSubscription} from '../../Redux/Actions/userActions'
import { BASE_URL } from '../../api/baseConfig'
import axios from 'axios';
import {useHistory} from 'react-router-dom'

const UserDetail = (props) => {
    const {user_id} = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [user, setUser] = useState({})
    const [showEmailForm, setShowEmailForm] = useState(false)
    const [showPhoneForm, setShowPhoneForm] = useState(false)
    const [showPasswordForm, setShowPasswordForm] = useState(false)
    const [newEmail, setNewEmail] = useState("")
    const [newPhone, setNewPhone] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [logs, setLogs] = useState([])
    const [payments, setPayments] = useState([])

    const history = useHistory()

    useEffect(() => {
        const fetchUser = async () => {
            try {
                setLoading(true)
                const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
                const response = await axios.post(`${BASE_URL}/api/user/check`, 
                    { user_id },
                    { headers: { "x-access-token": token } }
                )
                if (response.data) {
                    setUser(response.data)
                    setLogs(response.data.logs || [])
                    setPayments(response.data.payments || [])
                }
                setLoading(false)
            } catch (err) {
                setError(err.response?.data?.message || 'Failed to fetch user data')
                setLoading(false)
            }
        }
        fetchUser()
    }, [user_id])

    const handleEmailUpdate = (e) => {
        e.preventDefault()
        const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
        axios.post(`${BASE_URL}/api/user/update-user-by-admin`, 
            { user_id: user._id, email: newEmail },
            { headers: { "x-access-token": token } }
        ).then(res => {
            alert('Email updated successfully')
            setShowEmailForm(false)
            window.location.reload()
        }).catch(err => {
            alert('Failed to update email')
        })
    }

    const handlePhoneUpdate = (e) => {
        e.preventDefault()
        const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
        axios.post(`${BASE_URL}/api/user/update-user-by-admin`, 
            { user_id: user._id, phoneNumber: newPhone },
            { headers: { "x-access-token": token } }
        ).then(res => {
            alert('Phone number updated successfully')
            setShowPhoneForm(false)
            window.location.reload()
        }).catch(err => {
            alert('Failed to update phone number')
        })
    }

    const handlePasswordUpdate = (e) => {
        e.preventDefault()
        const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
        axios.post(`${BASE_URL}/api/user/update-user-by-admin`, 
            { user_id: user._id, password: newPassword },
            { headers: { "x-access-token": token } }
        ).then(res => {
            alert('Password updated successfully')
            setShowPasswordForm(false)
            setNewPassword("")
        }).catch(err => {
            alert('Failed to update password')
        })
    }

    const handleConfirmEmail = () => {
        const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
        axios.post(`${BASE_URL}/api/user/update-user-by-admin`, 
            { user_id: user._id, confirm_email: true },
            { headers: { "x-access-token": token } }
        ).then(res => {
            alert('Email confirmed successfully')
            window.location.reload()
        }).catch(err => {
            alert('Failed to confirm email')
        })
    }

    const handleConfirmPhone = () => {
        const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
        axios.post(`${BASE_URL}/api/user/update-user-by-admin`, 
            { user_id: user._id, confirm_phone: true },
            { headers: { "x-access-token": token } }
        ).then(res => {
            alert('Phone number confirmed successfully')
            window.location.reload()
        }).catch(err => {
            alert('Failed to confirm phone number')
        })
    }

    const submitHandler = (email) => {
        dispatch(cancelSubscription({email}))
        alert('OKAY təkrar ödəmə ləğv edildi')
        let new_user = user
        new_user.subscribtion_payment = false
        setUser(new_user)
    }

    const onRemoveClick = () => {
        const answer = window.confirm('Are you sure you want to remove this user?')
        if (answer) {
            const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
            axios.post(`${BASE_URL}/api/payment/removesubscribe`, {email: user.email}, {
                headers: { "x-access-token": token },
            }).then(res => {
                alert('User removed successfully')
                history.push('/users')
            })
        }
    }

    const onCheckPaymentClick = () => {
        const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
        axios.post(`${BASE_URL}/api/payment/checkpayment`, {user_id: user._id}, {
            headers: { "x-access-token": token },
        }).then(res => {
            alert('Payment checked successfully')
            window.location.reload()
        })
    }

    const handleRefund = (payment) => {
        const answer = window.confirm('Are you sure you want to refund this payment?')
        if (answer) {
            const token = JSON.parse(window.localStorage.getItem("userInfo")).token;
            axios.post(`${BASE_URL}/api/v1/payment/refund-payment`, {
                subscriber_payment_token_id: payment._id,
            }, {
                headers: { "x-access-token": token }
            }).then(res => {
                alert('Payment refunded request sent successfully')
                window.location.reload()
            }).catch(err => {
                alert('Failed to refund payment')
            })
        }
    }
    
    return (
        <>
            <div className="container py-4">
                {loading ? (
                    <Loading />
                ) : error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    user && (
                        <div className="card shadow-sm">
                            <div className="card-body p-4">
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header bg-light">
                                                <h4 className="mb-0">User Details</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="row g-4">
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Email</label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="h5 mb-0 me-2">{user.email}</div>
                                                                <i className={`fas fa-${user.confirm_email ? 'check-circle text-success' : 'times-circle text-danger'}`} 
                                                                   title={user.confirm_email ? 'Verified' : 'Not Verified'}></i>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">First Name</label>
                                                            <div className="h5">{user.firstName}</div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Last Name</label>
                                                            <div className="h5">{user.lastName}</div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Phone Number</label>
                                                            <div className="d-flex align-items-center">
                                                                <div className="h5 mb-0 me-2">{user.phoneNumber}</div>
                                                                <i className={`fas fa-${user.confirm_phone ? 'check-circle text-success' : 'times-circle text-danger'}`}
                                                                   title={user.confirm_phone ? 'Verified' : 'Not Verified'}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Registration Date</label>
                                                            <div className="h5">{user.registerDate}</div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Role</label>
                                                            <div className="h5">{user.claims}</div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Payment Card</label>
                                                            <div className="h5">{user.card}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <button className="btn btn-danger" onClick={onRemoveClick}>
                                                        <i className="fas fa-user-times me-2"></i>
                                                        Remove User
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header bg-light">
                                                <h4 className="mb-0">Update User Information</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="mb-4">
                                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                                        <div>
                                                            <h5 className="mb-0">Email Address</h5>
                                                        </div>
                                                        <div>
                                                            {!user.confirm_email && (
                                                                <button 
                                                                    className="btn btn-success btn-sm me-2"
                                                                    onClick={handleConfirmEmail}
                                                                >
                                                                    Confirm
                                                                </button>
                                                            )}
                                                            <button 
                                                                className="btn btn-outline-primary btn-sm"
                                                                onClick={() => setShowEmailForm(!showEmailForm)}
                                                            >
                                                                {showEmailForm ? 'Cancel' : 'Change Email'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {showEmailForm && (
                                                        <form onSubmit={handleEmailUpdate} className="mt-3">
                                                            <div className="mb-3">
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="Enter new email"
                                                                    value={newEmail}
                                                                    onChange={(e) => setNewEmail(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <button type="submit" className="btn btn-primary">
                                                                Update Email
                                                            </button>
                                                        </form>
                                                    )}
                                                </div>

                                                <div className="mb-4">
                                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                                        <div>
                                                            <h5 className="mb-0">Phone Number</h5>
                                                        </div>
                                                        <div>
                                                            {!user.confirm_phone && (
                                                                <button 
                                                                    className="btn btn-success btn-sm me-2"
                                                                    onClick={handleConfirmPhone}
                                                                >
                                                                    Confirm
                                                                </button>
                                                            )}
                                                            <button 
                                                                className="btn btn-outline-primary btn-sm"
                                                                onClick={() => setShowPhoneForm(!showPhoneForm)}
                                                            >
                                                                {showPhoneForm ? 'Cancel' : 'Change Phone'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {showPhoneForm && (
                                                        <form onSubmit={handlePhoneUpdate} className="mt-3">
                                                            <div className="mb-3">
                                                                <input
                                                                    type="tel"
                                                                    className="form-control"
                                                                    placeholder="Enter new phone number"
                                                                    value={newPhone}
                                                                    onChange={(e) => setNewPhone(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <button type="submit" className="btn btn-primary">
                                                                Update Phone
                                                            </button>
                                                        </form>
                                                    )}
                                                </div>

                                                <div className="mb-4">
                                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                                        <h5 className="mb-0">Password</h5>
                                                        <button 
                                                            className="btn btn-outline-primary btn-sm"
                                                            onClick={() => setShowPasswordForm(!showPasswordForm)}
                                                        >
                                                            {showPasswordForm ? 'Cancel' : 'Change Password'}
                                                        </button>
                                                    </div>
                                                    {showPasswordForm && (
                                                        <form onSubmit={handlePasswordUpdate} className="mt-3">
                                                            <div className="mb-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter new password"
                                                                    value={newPassword}
                                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                                    required
                                                                />
                                                            </div>
                                                            <button type="submit" className="btn btn-primary">
                                                                Update Password
                                                            </button>
                                                        </form>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header bg-light">
                                                <h4 className="mb-0">Subscription Details</h4>
                                            </div>
                                            <div className="card-body">
                                                <div className="row g-3">
                                                    <div className="col-md-6">
                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                            <div>
                                                                <label className="text-muted small mb-1">Auto Renewal</label>
                                                                <div className="h5 mb-0">
                                                                    {user.subscribtion_payment ? 'Active' : 'Inactive'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Payment Status</label>
                                                            <div className="h5">
                                                                {user.payment_validity ? 
                                                                    <span className="text-success">Valid</span> : 
                                                                    <span className="text-danger">Invalid</span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Last Payment</label>
                                                            <div className="h5">{user.last_payment_date}</div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="text-muted small mb-1">Next Payment</label>
                                                            <div className="h5">{user.next_payment_date}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <button className="btn btn-primary me-2" onClick={onCheckPaymentClick}>
                                                        <i className="fas fa-sync-alt me-2"></i>
                                                        Check Payment
                                                    </button>
                                                    {user.subscribtion_payment && (
                                                        <button
                                                            onClick={() => submitHandler(user.email)}
                                                            className="btn btn-danger"
                                                        >
                                                            <i className="fas fa-ban me-2"></i>
                                                            Cancel Auto-Renewal
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header bg-light">
                                                <h4 className="mb-0">Payments</h4>
                                            </div>
                                            <div>
                                                {payments && payments.length > 0 ? (
                                                    <div className="table-responsive" style={{ maxHeight: "400px", overflowY: "auto" }}>
                                                        <table className="table table-striped table-hover border">
                                                            <thead className="bg-light">
                                                                <tr>
                                                                    <th style={{ width: "15%" }} className="border-bottom">Date</th>
                                                                    <th style={{ width: "10%" }} className="border-bottom">Price</th>
                                                                    <th style={{ width: "15%" }} className="border-bottom">Payment Group</th>
                                                                    <th style={{ width: "15%" }} className="border-bottom">Host Reference</th>
                                                                    <th style={{ width: "30%" }} className="border-bottom">Refund Details</th>
                                                                    <th style={{ width: "15%" }} className="border-bottom">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {payments.map((payment) => (
                                                                    <tr key={payment._id}>
                                                                        <td className="align-middle">
                                                                            {new Date(payment.add_date).toLocaleDateString()} {new Date(payment.add_date).toLocaleTimeString()}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {payment.price} {payment.check_request_info?.response?.data?.currency || 'AZN'}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {payment.payment_type}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <div className="d-flex flex-column">
                                                                                <small className="d-block text-muted">
                                                                                    <span className="fw-bold">Payment ID:</span> {payment.check_request_info?.response?.data?.id}
                                                                                </small>
                                                                                <small className="d-block text-muted">
                                                                                    <span className="fw-bold">Payment Date:</span> {new Date(payment.add_date).toLocaleString()}
                                                                                </small>
                                                                                <small className="d-block text-muted">
                                                                                    <span className="fw-bold">Host Reference:</span> {payment.check_request_info?.response?.data?.hostReference || '-'}
                                                                                </small>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {payment.refund_response ? (
                                                                                <div>
                                                                                    {payment.refund_response.data ? (
                                                                                        <>
                                                                                            <small className="d-block">
                                                                                                <span className="fw-bold">ID:</span> {payment.refund_response.data.paymentTransactionRefunds[0].id}
                                                                                            </small>
                                                                                            <small className="d-block">
                                                                                                <span className="fw-bold">Date:</span> {new Date(payment.refund_response.data.createdDate).toLocaleString()}
                                                                                            </small>
                                                                                            <small className="d-block">
                                                                                                <span className="badge bg-info">{payment.refund_response.data.status}</span>
                                                                                            </small>
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="d-flex flex-column">
                                                                                            <div className="d-flex align-items-center">
                                                                                                <span className="fw-bold">Failed</span>
                                                                                            </div>
                                                                                            {Object.entries(payment.refund_response.errors).map(([key, value]) => (
                                                                                                <small key={key} className="d-block text-muted">
                                                                                                    <span className="fw-bold">{key}:</span> {value}
                                                                                                </small>
                                                                                            ))}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="d-flex align-items-center">
                                                                                    <span className="text-secondary fw-bold">Not Refunded</span>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td className="align-middle">
                                                                                <button 
                                                                                    className='btn btn-danger'
                                                                                    onClick={() => handleRefund(payment)}
                                                                                >
                                                                                    <i className="fas fa-undo me-2"></i>
                                                                                    Refund
                                                                                </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-info">No payment history available</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header bg-light">
                                                <h4 className="mb-0">Odero Logs</h4>
                                            </div>
                                            <div >
                                                {logs && logs.length > 0 ? (
                                                    <div className="table-responsive" style={{ maxHeight: "400px", overflowY: "auto" }}>
                                                        <table className="table table-striped table-hover border">
                                                            <thead className="bg-light">
                                                                <tr>
                                                                    <th style={{ width: "15%" }} className="border-bottom">Date</th>
                                                                    <th style={{ width: "15%" }} className="border-bottom">Process Type</th>
                                                                    <th style={{ width: "10%" }} className="border-bottom">Status</th>
                                                                    <th style={{ width: "60%" }} className="border-bottom">Details</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {logs.map((log) => (
                                                                    <tr key={log._id}>
                                                                        <td className="align-middle">{log.add_date.split('T')[0]} {new Date(log.add_date).toLocaleTimeString()}</td>
                                                                        <td className="align-middle">
                                                                            <span className={`badge bg-${log.process_type === 'notification' ? 'primary' : log.process_type === 'cancel_plan' ? 'warning' : 'danger'}`}>
                                                                                {log.process_type}
                                                                            </span>
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <span className={`badge ${log.error ? 'bg-danger' : 'bg-success'} text-white`}>
                                                                                {log.error ? 'Failed' : 'Completed'}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            {log.process_type === 'notification' && log.request && (
                                                                                <div>
                                                                                    <small className="d-block text-muted mb-1"><span className="fw-bold">Payment ID:</span> {log.request.id}</small>
                                                                                    <small className="d-block text-muted mb-1"><span className="fw-bold">Payment Status:</span> {log.request.paymentStatus}</small>
                                                                                    <small className="d-block text-muted mb-1"><span className="fw-bold">Order ID:</span> {log.request.orderId}</small>
                                                                                    <small className="d-block text-muted mb-1"><span className="fw-bold">Price:</span> {log.request.paidPrice}</small>
                                                                                    {log.request.subscriptionId && (
                                                                                        <small className="d-block text-muted"><span className="fw-bold">Subscription ID:</span> {log.request.subscriptionId}</small>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                            {['cancel_plan', 'delete_account'].includes(log.process_type) && !!log.response && (
                                                                                <div>
                                                                                    <small className="d-block text-muted">
                                                                                        {Object.entries(log.response).map(([key, value]) => (
                                                                                            <div key={key}>
                                                                                                <span className="fw-bold">{key}:</span> {value}
                                                                                            </div>
                                                                                        ))}
                                                                                    </small>
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-info">No subscription logs available</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                )}
            </div>
        </>
    )
}

export default UserDetail
