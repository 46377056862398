import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../LoadingError/Toast";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import Select from "react-select";
import slugify from "slugify";
import { getCategoryList, getCatalogList, getActorList, getDirectorList, getPlatformList, getLanguageList, getContentTypeList, getPartnerList, getSerieById, createSerie } from "../../api/metaflixApi";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
const AddSerieMain = () => {
  const [seriesInfo, setSeriesInfo] = useState([
    {
      lang_code: "",
      name: "",
      description: "",
    },
    {
      lang_code: "",
      name: "",
      description: "",
    },
    {
      lang_code: "",
      name: "",
      description: "",
    },
    {
      lang_code: "",
      name: "",
      description: "",
    },
  ]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [directorIds, setDirectorIds] = useState([]);
  const [actorIds, setActorIds] = useState([]);
  const [featured, setFeatured] = useState(false);
  const [isSlider, setIsSlider] = useState(false);
  const [trailers, setTrailers] = useState([]);
  const [trailerName, setTrailerName] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [imdb, setImdb] = useState("");
  const [catalogIds, setCatalogIds] = useState([]);
  const [platformId, setPlatformId] = useState(null);
  const [mainClaim, setMainClaim] = useState("");
  const [contentTypeId, setContentTypeId] = useState("");
  const [slugName, setSlugName] = useState("");
  const [hide, setHide] = useState(false)
  const [announcment, setAnnouncment] = useState("");
  const [releaseDate, setReleaseDate] = useState("");


  const [age, setAge] = useState(18);
  const [price, setPrice] = useState(0);
  const [previewImage, setPreviewImage] = useState("");
  const [previewBackground, setPreviewBackground] = useState("");
  const [mainImg, setMainImg] = useState("");
  const [mainBack, setMainBack] = useState("");

  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [actors, setActors] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [partners, setPartners] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);



  const [isPartner, setIsPartner] = useState(false)
  const [partnerId, setPartnerId] = useState('')
  const [partnerType, setPartnerType] = useState('');
  const [percent, setPercent] = useState(0);
  const [priceForView, setPriceForView] = useState(0);

  const onSuccessSubmit = useCallback(() => {
    toast.success("Series Added", ToastObjects);
    setCategoryIds([]);
    setActorIds([]);
      setDirectorIds([]);
      setSeriesInfo([]);
      setCatalogIds([]);
      setTrailerName("");
      setTrailerUrl("");
      setTrailers([]);
      setAge(0);
      setImdb("");
      setFeatured("");
      setPrice(0);
      setReleaseDate("");
      setAnnouncment("");
  }, [])

  useEffect(() => {
    
    getCategoryList({lang: "AZ", page: 1, q: ""}).then(res => {
      setCategories(res.data)
    })
    getCatalogList({lang: "AZ", page: 1, q: ""}).then(res => {
      setCatalogs(res.data)
    })
    getActorList({page: 1, q: ""}).then(res => {
      setActors(res.data)
    })
    getDirectorList({page: 1}).then(res => {
      setDirectors(res.data)
    })
    getPlatformList().then(res => {
      setPlatforms(res.data)
    })
    getPartnerList({startDate: "", endDate: ""}).then(res => {
      setPartners(res.data.message)
    })
    getLanguageList().then(res => {
      setLanguages(res.data)
    })
    getContentTypeList({lang: "AZ"}).then(res => {
      setContentTypes(res.data)
    })

  }, []);

  const partnerTypeHandler = React.useCallback((e) => {
    setPartnerType(e.target.value)
    setPercent(0);
    setPriceForView(0);
  }, []);


  const submitHandler = (e) =>{
    e.preventDefault();
    const serieData = {
      price,
      categories: categoryIds?.map(c => c.id),
      my_lang: seriesInfo,
      platform: platformId,
      type: contentTypeId.name,
        catalogs: catalogIds?.map(c => c.id),
        age,
        slug: slugName,
        claims: mainClaim,
        is_featured: featured,
        is_slider: isSlider,
        imdb,
        trailers,
        actors: actorIds?.map(c => c.id),
        directors: directorIds?.map(c => c.id),
        main_picture: "",
        slider_image: "",
        content_type: contentTypeId.id,
        hide,
        partner_type : partnerType,
        partner_id: partnerId,
        announcment,
        release_date: releaseDate
      }
      const files = { mainImg, mainBack }
      setLoading(true)
      createSerie({serie: serieData, files}).then(res => {
        toast.success("Series Added", ToastObjects)
        setLoading(false)
        onSuccessSubmit()
      }).catch(err => {
        toast.error(err.response.data.message, ToastObjects)
        setError(err.response.data.message)
        setLoading(false)
      })
  };
  const handleImageUpload = (file) => {
    setMainImg(file);
    previewFile(file, setPreviewImage);
  };
  const previewFile = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      state(reader.result);
    };
  };
  const handleBackgroundUpload = (file) => {
    setMainBack(file);
    previewFile(file, setPreviewBackground);
  };

  const handleChangePrice = (price) => {
    if (Number(price) >= 0 || typeof price !== "undefined") {
      setMainClaim("");
    }
    setPrice(parseFloat(price));
  };

  const handleChangeCategory = (option) => {
    setCategoryIds(option);
  };
  const handleGetCategory = (value) => {
    getCategoryList({lang: "AZ", page: 1, q: value}).then(res => {
      setCategories(res.data)
    })
  }

  const handleChangeCatalogs = (option) => {
    setCatalogIds(option)
  };
  const handleGetCatalog = (value) => {
    getCatalogList({lang: "AZ", page: 1, q: value}).then(res => {
      setCatalogs(res.data)
    })
  }


  const handleChangeActors = (option) => {
    setActorIds(option)
  };

  const handleGetActorName = (option) => {
    getActorList({ page: 1, q: option }).then(res => {
      setActors(res.data)
    })
  };

  const handleChangeDirectors = (option) => {
    setDirectorIds([]);
    option.map((c) => setDirectorIds((prev) => [...prev, c.id]));
  };


  const handleChangeLanguage = (e, i, l) => {
    const { name, value } = e.target;
    const newState = [...seriesInfo];
    newState[i] = {
      ...newState[i],
      lang_code: l,
      [name]: value,
    };
    setSeriesInfo(newState);
    if (l.toLowerCase() === "az" && value !== "" && name === "name") {
      setSlugName(slugify(value.toLowerCase()))
    }
  };

  const handleRemoveTrailer = (e) => {
    const ind = Number(e.target.getAttribute("trailer-index"));
    setTrailers((ts) => ts.filter((_, i) => i !== ind));
  };
  const handleAddTrailer = (e, name, url) => {
    e.preventDefault();
    setTrailers((state) => [...state, { name, url }]);
    setTrailerName("");
    setTrailerUrl("");
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler} >
          <div className="content-header">
            <Link to="/series" className="btn btn-danger text-white">
              Go to Series
            </Link>
            <h2 className="content-title">Add Serie</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-8 col-lg-8">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}

                  {contentTypes && (
                    <div className="mb-4">
                      <label className="form-label">Types</label>
                      <select
                        defaultValue={"DEFAULT"}
                        className="form-control"
                        onChange={(e) => setContentTypeId({ id: e.target.value.split("$")[0], name: e.target.value.split("$")[1] })}
                      >
                        <option value="DEFAULT" disabled>
                          Type ...
                        </option>
                        {contentTypes &&
                          contentTypes.filter(c => c.type !== "film").map((p) => (
                            <option value={`${p.id}$${p.slug}`} key={p.id}>
                              {p.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                  <div className="mb-4">
                    <label className="form-label">Platforms</label>
                    <select
                      defaultValue={"DEFAULT"}
                      className="form-control"
                      onChange={(e) => setPlatformId(e.target.value)}
                    >
                      <option value="DEFAULT" disabled>
                        Platform ...
                      </option>
                      {platforms &&
                        platforms.map((p) => (
                          <option value={p.id} key={p.id}>
                            {p.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <Tabs>
                    {languages && seriesInfo.length > 0 &&
                      languages.map((l, index) => (
                        <Tab
                          eventKey={l.name}
                          title={l.name}
                          key={`movie-tab-${l.id}`}
                        >
                          <div className="mb-4">
                            <label
                              htmlFor={`product_title-${l.id}`}
                              className="form-label"
                            >
                              Movie title {l.name}
                            </label>
                            <input
                              type="text"
                              placeholder="Type here"
                              className="form-control"
                              id={`product_title-${l.id}`}
                              name="name"
                              required
                              value={seriesInfo[index].name}
                              onChange={(e) =>
                                handleChangeLanguage(e, index, l.name)
                              }
                            />
                          </div>

                          <div className="mb-4">
                            <label className="form-label">Description</label>
                            <textarea
                              id={`movie_desc-${l.id}`}
                              placeholder="Type here"
                              className="form-control"
                              name={`description`}
                              rows="7"
                              value={seriesInfo[index].description}
                              onChange={(e) =>
                                handleChangeLanguage(e, index, l.name)
                              }
                            ></textarea>
                          </div>
                        </Tab>
                      ))}
                  </Tabs>
                  <div className="mb-4">
                    <label htmlFor="slug">Slug()</label>
                    <input className="form-control" placeholder="slug" id="slug" name="slug"
                      value={slugName}
                      required
                      onChange={e => setSlugName(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="categories" className="form-label">
                      Categories
                    </label>
                    {categories && categories.data && (
                      <Select
                        onChange={handleChangeCategory}
                        onInputChange={handleGetCategory}
                        isMulti={true}
                        options={categories.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="catalogs" className="form-label">
                      Catalogs
                    </label>
                    {catalogs && (
                      <Select
                        onChange={handleChangeCatalogs}
                        onInputChange={handleGetCatalog}
                        isMulti={true}
                        options={catalogs.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="product_price"
                      min={0}
                      value={price}
                      onChange={(e) => handleChangePrice(e.target.value)}
                    />
                  </div>
                  {(price === 0 || typeof price === "undefined") ? (
                    <div className="mb-4">
                      <label htmlFor="plan" className="form-label">
                        Plan
                      </label>
                      <select className="form-control" defaultValue={""} required onChange={e => setMainClaim(e.target.value)}>
                        <option value={""}>-</option>
                        <option value="Free">Free</option>
                        <option value="Subscriber">Subscriber</option>
                        <option value="UnSubscriber">UnSubscriber</option>
                        <option value="Pro">Pro</option>
                      </select>
                    </div>
                  ) : null}

                  <div className="mb-4">
                    <label htmlFor="movie_age" className="form-label">
                      Age
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className="form-control"
                      id="movie_age"
                      required
                      value={age}
                      onChange={(e) => setAge(e.target.value)}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="movie_imdb" className="form-label">
                      IMDB
                    </label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="movie_imdb"
                      value={imdb}
                      onChange={(e) => setImdb(e.target.value)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="actors" className="form-label">
                      Actors
                    </label>
                    {actors && (
                      <Select
                        onChange={handleChangeActors}
                        onInputChange={handleGetActorName}
                        isMulti={true}
                        id="actors"
                        options={actors.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>

                  <div className="mb-4">
                    <label htmlFor="directors" className="form-label">
                      Directors
                    </label>
                    {directors && (
                      <Select
                        onChange={handleChangeDirectors}
                        isMulti={true}
                        id="directors"
                        options={directors.data}
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                      />
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="form-label">Images</label>
                    <input
                      className="form-control mt-3"
                      type="file"
                      onChange={(e) => handleImageUpload(e.target.files[0])}
                    />
                    {previewImage && (
                      <img width={150} src={previewImage} alt="" />
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Background Images</label>
                    <input
                      className="form-control mt-3"
                      type="file"
                      onChange={(e) =>
                        handleBackgroundUpload(e.target.files[0])
                      }
                    />
                    {previewBackground && (
                      <img width={150} src={previewBackground} alt="" />
                    )}
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="movie_featured"
                      className="form-check-label me-2"
                    >
                      Gizlədilsin? (Saytda görünməyəcək)
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="movie_featured"
                      value={featured}
                      onChange={(e) => setHide(e.target.checked)}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="movie_featured"
                      className="form-check-label me-2"
                    >
                      Önə çıxarılsın?
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="movie_featured"
                      value={featured}
                      onChange={(e) => setFeatured(e.target.checked)}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="movie_slider"
                      className="form-check-label me-2"
                    >
                      Slider hissəsində göstərilsin?
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="movie_slider"
                      value={isSlider}
                      onChange={(e) => setIsSlider(e.target.checked)}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="release_date" className="form-label">Release Date</label>
                    <input type="text" className="form-control" id="release_date" name="release_date" value={releaseDate} onChange={(e) => setReleaseDate(e.target.value)} />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="announcment" className="form-label">Announcment</label>
                    <input type="text" className="form-control" id="announcment" name="announcment" value={announcment} onChange={(e) => setAnnouncment(e.target.value)} />
                  </div>
                  

                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="mb-4 ">
                      <label htmlFor={`movie_trailer`} className="form-label">
                        Trailer Name
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className="form-control"
                        id={`movie_trailer`}
                        name="movie_trailer"
                        value={trailerName}
                        onChange={(e) => setTrailerName(e.target.value)}
                      />
                    </div>

                    <div className="mb-4 ms-2 flex-grow-1">
                      <label
                        htmlFor={`movie_trailer_url`}
                        className="form-label"
                      >
                        Trailer Url
                      </label>
                      <input
                        type="text"
                        placeholder="Type here"
                        className="form-control"
                        id={`movie_trailer_url`}
                        name="movie_trailer_url"
                        value={trailerUrl}
                        onChange={(e) => setTrailerUrl(e.target.value)}
                      />
                    </div>
                    <div className="ms-5 mt-2">
                      <button
                        className="btn btn-dark"
                        onClick={(e) =>
                          handleAddTrailer(e, trailerName, trailerUrl)
                        }
                      >
                        Add Trailer
                      </button>
                    </div>
                  </div>
                  <div className="mb-4">
                    <ul className=" w-100 p-0">
                      {trailers.map((t, id) => (
                        <li
                          style={{ border: "1px solid black" }}
                          key={id}
                          className="list-group-item align-items-center justify-content-between d-flex"
                        >
                          trailer: {t.name} &nbsp;&nbsp;|&nbsp;&nbsp; url:
                          {t.url}
                          <div
                            trailer-index={id}
                            className="p-2 text-danger"
                            onClick={handleRemoveTrailer}
                          >
                            <i
                              style={{ pointerEvents: "none" }}
                              className="fas fa-times"
                            ></i>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="add_partner"
                      className="form-check-label me-2"
                    >
                      Partnyor əlavə olunsun?
                    </label>
                    <input
                      type="checkbox"
                      placeholder="Type here"
                      className="form-check-input"
                      id="add_partner"
                      value={isPartner}
                      onChange={(e) => setIsPartner(e.target.checked)}
                    />

                  </div>

                  <div className={`${isPartner ? 'd-block' : 'd-none'}`}>
                    <div className="mb-4">
                      <label>Partner Type</label>
                      <select onInput={(e)=>{partnerTypeHandler(e)}} className="form-control">
                      <option value={null}>Seçilmədi</option>
                        <option
                          value={'price'}
                          key={'price'}
                          >Faizlə
                        </option>
                        <option
                          value={'subscriber'}
                          key={'subscriber'}
                          >Baxış sayına görə
                        </option>
                      </select>
                    </div>
                        
                    {partnerType==="price" ? <><div className="mb-4">
                      <label className="form-label">
                        Partners
                      </label>

                      <select onInput={e => setPartnerId(e.target.value)} id="directors" className="form-control">
                        <option value={null}>Seçilmədi</option>
                        {partners && partners.map(({ _id, firstName, lastName }) => (
                          <option
                            value={_id}
                            key={_id}>
                            {firstName} {lastName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="form-label">
                        Faiz payı
                      </label>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        placeholder="14"
                        className="form-control"
                        id="percent"
                        value={percent}
                        onChange={(e) => setPercent(e.target.value)}
                      />
                    </div></> : partnerType==="subscriber" ? <><div className="mb-4">
                      <label className="form-label">
                        Partners
                      </label>

                      <select onInput={e => setPartnerId(e.target.value)} id="directors" className="form-control">
                        <option value={null}>Seçilmədi</option>
                        {partners && partners.map(({ _id, firstName, lastName }) => (
                          <option
                            value={_id}
                            key={_id}>
                            {firstName} {lastName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-4">
                      <label className="form-label d-block">
                          Hər baxışa
                      </label>
                      <input
                        type="number"
                        step={0.01}
                        min={0}
                        placeholder="0"
                        className="form-control"
                        id="viewCount"
                        value= {priceForView}
                        onChange={(e) => setPriceForView(e.target.value)}
                      />
                    </div></>: null}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddSerieMain;
