import React from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import HomeScreen from "./screens/HomeScreen";
import ProductScreen from "./screens/productScreen";
import CategoriesScreen from "./screens/CategoriesScreen";
import OrderScreen from "./screens/OrderScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import AddProduct from "./screens/AddProduct";
import Login from "./screens/LoginScreen";
import UsersScreen from "./screens/UsersScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import PartnerRouter from "./PartnerRouter";
// import { useDispatch, useSelector } from "react-redux";
// import { listProducts } from "./Redux/Actions/ProductActions";
import ActorsScreen from "./screens/ActorsScreen";
import DirectorsScreen from "./screens/DirectorsScreen";
import PlatformsScreen from "./screens/PlatformsScreen";
import PlatformsRefScreen from "./screens/PlatformsRefScreen";
import CatalogsScreens from "./screens/CatalogsScreens";
import SerieEditScreen from "./screens/SerieEditScreen";
import AddSeries from "./screens/AddSeries";
import SeriesScreen from "./screens/SeriesScreen";
import SeosonEpisodeScreen from "./screens/SeosonEpisodeScreen";
import AddSeasonScreen from "./screens/AddSeasonScreen";
import SeasonEditScreen from "./screens/SeasonEditScreen";
import AddEpisodeScreen from "./screens/AddEpisodeScreen";
import EpisodeListScreen from "./screens/EpisodeListScreen";
import EpisodeEditScreen from "./screens/EpisodeEditScreen";
import PartnerStatisticsScreen from "./screens/PartnerStatisticsScreen";
import PartnerPayment from "./screens/PartnerPayment"
import PartnerListScreen from "./screens/PartnerListScreen";
import PartnerScreen from "./screens/PartnerScreen";
import ContactScreen from "./screens/ContactScreen";
import ContactDetailScreen from "./screens/ContactDetailScreen";
import SubscriptionScreen from "./screens/SubscriptionScreen";
import SubscriptionDetailScreen from './screens/SubscriptionDetailScreen'
import SubAddScreen from './screens/SubAddScreen'
import InteractiveScreen from './screens/InteractiveScreen'
import InteractiveScenariosScreen from './screens/InteractiveScenariosScreen'
import InteractiveScenarioFormScreen from './screens/InteractiveScenarioFormScreen'
import PartnerViewsScreen from './screens/PartnerViewsScreen'
import GiftcardScreen from "./screens/GiftcardScreen";
import PaymentsScreen from "./screens/PaymentsScreen";
import GiftcardAddScreen from "./screens/GiftcardAddScreen";
import UserDetailScreen from "./screens/UserDetailScreen"
import SearchCardScreen from "./screens/OderoSearchScreen";
import OderoSubscriptionScreen from "./screens/OderoSubscriptionScreen";
import ContentTypesScreen from "./screens/ContentTypesScreens";
import PartnerSeriesScreen from "./screens/PartnerSeriesScreen";
import PartnerEpisodesScreen from "./screens/PartnerEpisodesScreen";
import LiveBulkLoadScreen from "./screens/LiveBulkLoadScreen";
import LiveCategoryScreen from "./screens/LiveCategoryScreen";
import AddLiveCategoryScreen from "./screens/AddLiveCategoryScreen";
import EditLiveCategoryScreen from "./screens/EditLiveCategoryScreen";
import LiveChannelScreen from "./screens/LiveChannelScreen";
import AddLiveChannelScreen from "./screens/AddLiveChannelScreen";
import EditLiveChannelScreen from "./screens/EditLiveChannelScreen";
function App() {
  // const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // useEffect(() => {
  //   if (userInfo && userInfo.isAdmin) {
  //     dispatch(listProducts("AZ"));
  // dispatch(listOrders());
  //   }
  // }, [dispatch, userInfo]);

  return (
    <>
      <Router>
        <Switch>

          <Route path="/"
            component={((userInfo && userInfo.user.claims.includes('Admin')) ||
              (userInfo && userInfo.user.claims.includes('partner'))) ?
              HomeScreen : Login} exact />

          <PrivateRouter path="/products" component={ProductScreen} />
          <PrivateRouter path="/odero/search" component={SearchCardScreen} />
          <PrivateRouter path="/odero/subscription" component={OderoSubscriptionScreen} />
          <PrivateRouter path="/series" component={SeriesScreen} />
          {["/category", "/category/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={CategoriesScreen}
              key={index}
            />
          ))}
          {["/catalog", "/catalog/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={CatalogsScreens}
              key={index}
            />
          ))}
          {["/contentType", "/contentType/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={ContentTypesScreen}
              key={index}
            />
          ))}

          {["/actor", "/actor/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={ActorsScreen}
              key={index}
            />
          ))}
          {["/director", "/director/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={DirectorsScreen}
              key={index}
            />
          ))}

          {["/platform", "/platform/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={PlatformsScreen}
              key={index}
            />
          ))}
          {["/platform-ref", "/platform-ref/:id/edit"].map((path, index) => (
            <PrivateRouter
              exact
              path={path}
              component={PlatformsRefScreen}
              key={index}
            />
          ))}
          {/* <PrivateRouter  path="/movie/:id/:type" element={<  />} /> */}
          <PrivateRouter path="/partnerScreen/:id" component={PartnerScreen} />
          <PrivateRouter path="/partnerSeries/:id" component={PartnerSeriesScreen} />
          <PrivateRouter path="/partnerEpisodes/:id" component={PartnerEpisodesScreen} />
          <PrivateRouter path="/orders" component={OrderScreen} />
          <PrivateRouter path="/order/:id" component={OrderDetailScreen} />
          <PrivateRouter path="/addMovie" component={AddProduct} />
          <PrivateRouter path="/users" component={UsersScreen} />
          <PrivateRouter path="/user/:user_id" component={UserDetailScreen} />

          <PrivateRouter
            exact
            path="/product/:id/edit"
            component={ProductEditScreen}
          />
          <PrivateRouter path="/season-episode/:id/getall" component={SeosonEpisodeScreen} />
          <PrivateRouter path="/addSeries" component={AddSeries} />
          <PrivateRouter path="/addEpisode/:id/" component={AddEpisodeScreen} />
          <PrivateRouter path="/episodes/:id/" component={EpisodeListScreen} />
          <PrivateRouter path="/episode/:id/edit" component={EpisodeEditScreen} />

          <PrivateRouter path="/add-season/:id" component={AddSeasonScreen} />
          <PrivateRouter path="/season/:id/:tvshowId/edit" component={SeasonEditScreen} />

          <PrivateRouter path="/serie/:id/edit" component={SerieEditScreen} />
          <PrivateRouter path="/live/bulkLoad" component={LiveBulkLoadScreen} />
          <PrivateRouter path="/live/category" exact component={LiveCategoryScreen} />
          <PrivateRouter path="/live/category/add" component={AddLiveCategoryScreen} />
          <PrivateRouter path="/live/category/edit/:id" component={EditLiveCategoryScreen} />
          <PrivateRouter path="/live/channels" exact component={LiveChannelScreen} />
          <PrivateRouter path="/live/channels/add" component={AddLiveChannelScreen} />
          <PrivateRouter path="/live/channels/edit/:id" component={EditLiveChannelScreen} />
          <Route path="/login" component={Login} />
          <PrivateRouter path="/partnerList" component={PartnerListScreen} />
          <PartnerRouter path="/partnerStatistics" component={PartnerStatisticsScreen} />
          <PrivateRouter path="/partnerPayment" component={PartnerPayment} />
          <Route path="/sub-detail/:id" component={SubscriptionDetailScreen} />
          <Route path="/subscription" component={SubscriptionScreen} />
          <Route path="/add-package" component={SubAddScreen} />
          <Route path="/contact-detail/:id" component={ContactDetailScreen} />
          <Route path="/contact" component={ContactScreen} />
          <Route path="/interactive/:filmId/scenarios/:scenarioId" component={InteractiveScenarioFormScreen} />
          <Route path="/interactive/:filmId/scenarios" exact component={InteractiveScenariosScreen} />
          <Route path="/interactive" component={InteractiveScreen} />
          <Route path="/partnerViews/:id" component={PartnerViewsScreen} />
          <Route path="/giftcards/add" component={GiftcardAddScreen}/>
          <Route path="/giftcards" component={GiftcardScreen}/>
          <Route path='/payments' component={PaymentsScreen}/>
          <PrivateRouter path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
