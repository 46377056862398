import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userListReducer, userLoginReducer,findUserReducer,cancelSubscriptionReducer } from "./Reducers/userReducers";
import {
  filmBackImageReducer,
  filmImageReducer,
  productCreateReducer,
  productDeleteReducer,
  productEditReducer,
  productListReducer,
  productUpdateReducer,
} from "./Reducers/ProductReducers";
import {
  orderDeliveredReducer,
  orderDetailsReducer,
  orderListReducer,
} from "./Reducers/OrderReducres";
import { 
  contentTypeListReducer, 
  contentTypeCreateReducer, 
  contentTypeUpdateReducer, 
  contentTypeDeleteReducer 
} from "./Reducers/ContentTypeReducers";
import { categoryCreateReducer, categoryEditReducer, categoryListReducer, categoryUpdateReducer } from "./Reducers/CategoryReducers";
import { languageListReducer } from "./Reducers/LanguageReducers";
import { catalogCreateReducer, catalogEditReducer, catalogListReducer, catalogUpdateReducer } from "./Reducers/CatalogReducers";
import { platformCreateReducer, platformEditReducer, platformListReducer, platformUpdateReducer } from "./Reducers/PlatformReducers";
import { seasonCreateReducer, seasonDeleteReducer, seasonEditReducer, seasonListReducer, seasonUpdateReducer } from "./Reducers/SeasonReducers";
import { actorCreateReducer, actorEditReducer, actorListReducer, actorUpdateReducer } from "./Reducers/ActorReducers";
import { directorCreateReducer, directorEditReducer, directorListReducer, directorUpdateReducer } from "./Reducers/DirectorReducers";
import { platformRefCreateReducer, platformRefDeleteReducer, platformRefEditReducer, platformRefListReducer, platformRefUpdateReducer } from "./Reducers/PlatformRefReducers";
import { serieCreateReducer, serieDeleteReducer, serieEditReducer, serieListReducer, serieUpdateReducer } from "./Reducers/SerieReducers";
import { episodeCreateReducer, episodeDeleteReducer, episodeEditReducer, episodeListReducer, episodeUpdateReducer } from "./Reducers/EpisodeReducers";
import { partnerListReducer,payToPartnerReducer } from './Reducers/PartnersReducers';


const reducer = combineReducers({
  userLogin: userLoginReducer,
  cancelSubscription: cancelSubscriptionReducer,
  userList: userListReducer,
  findUser:findUserReducer,
  productList: productListReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  payToPartner: payToPartnerReducer,
  productEdit: productEditReducer,
  productUpdate: productUpdateReducer,
  serieList: serieListReducer,
  serieDelete: serieDeleteReducer,
  serieCreate: serieCreateReducer,
  serieEdit: serieEditReducer,
  serieUpdate: serieUpdateReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  orderDeliver: orderDeliveredReducer,
  contentTypeList: contentTypeListReducer,
  contentTypeCreate: contentTypeCreateReducer,
  contentTypeUpdate: contentTypeUpdateReducer,
  contentTypeDelete: contentTypeDeleteReducer,
  languageList: languageListReducer,
  filmBackground: filmBackImageReducer,
  filmMainImage: filmImageReducer,
  platformList: platformListReducer,
  platformCreate: platformCreateReducer,
  platformUpdate: platformUpdateReducer,
  platformEdit: platformEditReducer,
  platformRefList: platformRefListReducer,
  platformRefCreate: platformRefCreateReducer,
  platformRefUpdate: platformRefUpdateReducer,
  platformRefEdit: platformRefEditReducer,
  platformRefDelete: platformRefDeleteReducer,
  actorList: actorListReducer,
  actorCreate: actorCreateReducer,
  actorUpdate: actorUpdateReducer,
  actorEdit: actorEditReducer,
  directorList: directorListReducer,
  directorCreate: directorCreateReducer,
  directorUpdate: directorUpdateReducer,
  directorEdit: directorEditReducer,
  seasonList: seasonListReducer,
  seasonCreate: seasonCreateReducer,
  seasonUpdate: seasonUpdateReducer,
  seasonEdit: seasonEditReducer,
  seasonDelete: seasonDeleteReducer,
  episodeList: episodeListReducer,
  episodeCreate: episodeCreateReducer,
  episodeUpdate: episodeUpdateReducer,
  episodeEdit: episodeEditReducer,
  episodeDelete: episodeDeleteReducer,
  categoryList: categoryListReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryEdit: categoryEditReducer,
  catalogList: catalogListReducer,
  catalogCreate: catalogCreateReducer,
  catalogUpdate: catalogUpdateReducer,
  catalogEdit: catalogEditReducer,
  partnerList: partnerListReducer,
});

// login
const userInfoFromLocalStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromLocalStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
